.video{
    top:0;
    background: black;    
}

.video .react-player {
    position: absolute;
    left:0;
    top:0;
    object-fit: cover;
    width:100%;
    height: 100%;
}

.video .player-overlay {
    position: absolute;
    left:0;
    top:0;
    object-fit: cover;
    width:100%;
    height: 100%;
    background:black;
}

.video .react-player  video{

    object-fit: cover;

}

.video-wrapper {
    width:100%;
    height: 100%;
}

.video .toggle-play-button {
    position: absolute;
    z-index:10;
    bottom:5vw;
    right:5vw;
    width:11vw;
    
}

.interior-reserve-button{
    position: absolute;
    z-index: 1;
    top:5.3vw;
    right:5.3vw;
    background: transparent;
    border: 1px solid white;
    border-radius: 1vw;
    color:white;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 500;
    width:196px;
    padding:10px 24px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    transition: .5s ease-in-out all;
}

.interior-reserve-button:hover{
    background: white;
    color:black;
}


/* specific to over 420 */
@media only screen and (min-width: 420px) {
    
    .video .toggle-play-button {

        bottom:25px;
        right:25px;
        width:45px;
        
    }
    
    .interior-reserve-button{
        top:25px;
        right:25px;
        border-radius: 5px;
        width: 200px;
        height:50px;
        font-size: 14px;
        line-height:14px;
    }

}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}