.subscribe-landing{
    width:100%;
    height: 100%;
    font-family: 'Volvo Novum';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    z-index:10;
    top:0;
    left:0;
    background: black;
}

.subscribe-landing-background {
    object-fit: cover;
    width:100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 0;
    top:0;
    left:0;
    object-position: center 75%;
}

.subscribe-event-title {
    position: absolute;
    top:130px;
    left:5.3vw;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 3.733vw;
    text-align: right;
    color:black;
}

.subscribe-product-title {
    position: absolute;
    top:20%;
    left:5.3vw;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 3.733vw;
    color:black;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

.subscribe-title-block{
    position: absolute;
    top:190px;
    left:5.3vw;
    width:93vw;
}

.subscribe-title {  
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 300;
    font-size: 9.6vw;
    line-height: 11.2vw;
    color:black;
}

.subscribe-sub-title {  
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 300;
    font-size: 4.26vw;
    line-height: 6.4vw;
    color:black;
    margin-top:3vw;

}

.subscribe-order-button{
    background: transparent;
    border: 1px solid black;
    border-radius: 1vw;
    width: 39vw;
    color:black;
    height:12.8vw;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 500;
    font-size: 3.733vw;
    line-height: 3.733vw;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top:10vw;
    transition: .5s ease-in-out all;
    margin-right:7vw;
}

.subscribe-order-button:hover{
    background: black;
    color:white;
}


.subscribe-subscribe-button{
    background: transparent;
    border: 1px solid black;
    border-radius: 1vw;
    color:black;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 500;
    width:147px;
    padding:12px 24px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top:10vw;
    transition: .5s ease-in-out all;
    display: none;
}

.subscribe-subscribe-button:hover{
    background: black;
    color:white;
}

.subscribe-footer {
    color:black;
    width:80vw;
    left:10vw;
    font-size:12px;
    line-height: 15px;
    text-align: center;
    position: absolute;
    bottom:10px;
    font-weight: 400;
}

/* specific to over 420 */
@media only screen and (min-width: 420px) {
    .subscribe-event-title {
        font-size: 14px;
        line-height: 14px;
    }

    .subscribe-product-title {
        font-size: 14px;
        line-height: 14px;
    }

    .subscribe-title {  
        font-size: 36px;
        line-height: 42px;

    }
    
    .subscribe-sub-title {  
        font-size: 16px;
        line-height: 24px;
        margin-top:20px;
    }

    .subscribe-order-button{
        border-radius: 5px;
        width: 150px;
        height:50px;
        font-size: 14px;
        line-height:14px;
        margin-top:40px;
        margin-right: 25px;
    }

    .subscribe-subscribe-button{
        border-radius: 5px;
        width: 150px;
        height:50px;
        font-size: 14px;
        line-height:14px;
        margin-top:40px;
        margin-right: 25px;
    }
}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}