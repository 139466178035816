.steps{
    width:100%;
    background: transparent;
    color:white;
    font-family: 'Volvo Novum';
    font-style: normal;
    pointer-events:none;
    color:white;
}

.step {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
}

.step-block{
    position: absolute;
    top:50%;
    left:5.3vw;
    transform:translate(0,-50%);
}

.step-number {
    font-weight: 400;
    font-size: 3.733vw;
    line-height:100%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-bottom:6vh;
}

.step-title {
    font-weight: 300;
    font-size: 9.6vw;
    line-height:100%;
    margin-bottom:15vh;
}

.step-content {
    width:80%;
    font-weight: 400;
    font-size: 5.3vw;
    line-height:128%;
}



/* specific to over 420 */
@media only screen and (min-width: 420px) {
    .step-number {
        font-size: 14px;
        margin-bottom:6vh;
    }
    
    .step-title {
        font-size: 40px;
        margin-bottom:15vh;
    }
    
    .step-content {
        width:80%;
        font-size: 20px;
    }
}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}