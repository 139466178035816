.footer{
    font-family: 'Volvo Novum';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    color:#707070;
    background:#fafafa;
    height: 30%;
    min-height:30%;
}

.footer-links {
    display: flex!important;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-box-pack: center!important;
   
    margin-top:2vh;
}

.footer-links a {
    display: block!important;
    box-sizing: border-box!important;
    text-decoration: none!important;
    color: #141414!important;
    transition: color 150ms ease;
    -webkit-transition: color 150ms ease;
    -moz-transition: color 150ms ease!important;
    padding:12px;
    font-size:12px;
    line-height: 24px;
    font-weight: 300;
    
}

.footer .copyright {
    color: #707070;
    font-family: Volvo Novum, Arial, sans-serif;
    font-size: 12px;
    line-height: 1.6666666666666667;
    letter-spacing: 0.02em;
    font-weight: 300;
    display: block;
    text-align: center;
    width:80%;
    margin-left: 10%;
    
}


/* specific to over 420 */
@media only screen and (min-width: 420px) {
    
    .footer{
        font-size: 12px;
        line-height: 24px;
        height: 30%;
        min-height:30%;
    }

    
    .footer-links a {
        padding:12px;
        font-size:12px;
        line-height: 24px;
    }

    .footer .copyright {
        font-size: 12px;
        
    }
}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}