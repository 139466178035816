.c40{
    width:100%;
    height: 100%;
    position: relative;
    font-family: 'Volvo Novum';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    color: black;
   
}
    
.volvo-wordmark {
    position: fixed;
    top:36px;
    left:5.3vw;
    width:22.66vw;
    z-index: 1000;
}

.scroll-arrow {
    position: absolute;
    bottom:120px;
    left:46vw;
    width:8vw;
    z-index: 1000;
}

.section { 
    width:100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top:0;
    left:0;
    margin:0;
    padding:0;
    color:black;
}

.bottom{
    height: 140%;
    min-height: 140%;
}

.alt-header {
    background:white;
    height:70px;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    z-index: 1000;
    pointer-events: none;
}

.alt-volvo-wordmark {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
    top:36px;
    left:5.3vw;
    width:22.66vw;
    position:absolute;
}


/* specific to over 768 */
@media only screen and (min-width: 420px) {
    .volvo-wordmark {
        left:25px;
        width:100px;
    }

    .scroll-arrow {
        bottom:25px;
        left:50%;
        transform: translate(-50%,0);
        width:40px;
    }
}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}