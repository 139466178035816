.landing{
    width:100%;
    height: 100%;
    font-family: 'Volvo Novum';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    position: relative;
    z-index:10;
    top:0;
    left:0;
    background: black;
}

.landing-background {
    object-fit: cover;
    width:100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 0;
    top:0;
    left:0;
    object-position: center bottom;
}

.event-title {
    position: absolute;
    top:20px;
    right:5.3vw;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 3.733vw;
    text-align: right;
    color:white;
}

.product-title {
    position: absolute;
    top:20%;
    left:5.3vw;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 3.733vw;
    color:white;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

.title-block{
    position: absolute;
    top:28%;
    left:5.3vw;
    width:93vw;
}

.title {  
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 300;
    font-size: 9.6vw;
    line-height: 11.2vw;
    color:white;
}

.sub-title {  
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 400;
    font-size: 4.26vw;
    line-height: 6.4vw;
    color:white;
    margin-top:3vw;

}

.order-button{
    background: transparent;
    border: 1px solid white;
    border-radius: 1vw;
    color:white;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 500;
    width:147px;
    padding:12px 24px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top:10vw;
    transition: .5s ease-in-out all;
    margin-right:7vw;
}

.order-button:hover{
    background: white;
    color:black;
}


.subscribe-button{
    background: transparent;
    border: 1px solid white;
    border-radius: 1vw;
    color:white;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 500;
    width:147px;
    padding:12px 24px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top:10vw;
    transition: .5s ease-in-out all;
}

.subscribe-button:hover{
    background: white;
    color:black;
}

.legal-link {
    position: relative;
    top: -0.3rem;
    left: 47vw;
    width: 150px;
    line-height: 1rem;
    display: inline-block;
    margin-top: 0.9rem;
    font-weight: 400;
    font-size: 0.8rem;
    color: white;

}

/* specific to over 420 */
@media only screen and (min-width: 420px) {
    .event-title {
        font-size: 14px;
        line-height: 14px;
    }

    .product-title {
        font-size: 14px;
        line-height: 14px;
    }

    .title {  
        font-size: 36px;
        line-height: 42px;

    }
    
    .sub-title {  
        font-size: 16px;
        line-height: 24px;
        margin-top:20px;
    }

    .order-button{
        border-radius: 5px;
        width: 150px;
        height:50px;
        font-size: 14px;
        line-height:14px;
        margin-top:40px;
        margin-right: 25px;
    }

    .subscribe-button{
        border-radius: 5px;
        width: 150px;
        height:50px;
        font-size: 14px;
        line-height:14px;
        margin-top:40px;
        margin-right: 25px;
    }
}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}