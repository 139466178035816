@font-face {
  font-family: 'Volvo Serif Pro';
  src: local('Volvo Serif Pro'),  url('./assets/fonts/VolvoSerifPro-Italic.woff2') format('woff2'),
      url('./assets/fonts/VolvoSerifPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: local('Volvo Novum'),  url('./assets/fonts/VolvoNovum-Regular.woff2') format('woff2'),
      url('./assets/fonts/VolvoNovum-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: local('Volvo Novum'),  url('./assets/fonts/VolvoNovum-Medium.woff2') format('woff2'),
      url('./assets/fonts/VolvoNovum-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: local('Volvo Novum'),  url('./assets/fonts/VolvoNovum-Light.woff2') format('woff2'),
      url('./assets/fonts/VolvoNovum-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: local('Volvo Novum'),  url('./assets/fonts/VolvoNovum-Italic.woff2') format('woff2'),
      url('./assets/fonts/VolvoNovum-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Serif Pro';
  src: local('Volvo Serif Pro'),  url('./assets/fonts/VolvoSerifPro-Regular.woff2') format('woff2'),
      url('./assets/fonts/VolvoSerifPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Broad Pro';
  src: local('Volvo Broad Pro'),  url('./assets/fonts/VolvoBroadPro.woff2') format('woff2'),
      url('./assets/fonts/VolvoBroadPro.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html{
  height: 100%;
}

body{
  width:100%;
  height:100vh;
  margin:0;
  padding:0;  
  overflow-x: hidden;
  overflow-y:scroll;

}

#root {
  width:100%;
  height: 100%;
  max-width: 768px;
  margin-left:auto;
  margin-right:auto;
}