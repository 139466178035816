.App {
  width:100%;
  height: 100%;
}

/* small reset */
h1,h2,h3,h4,h5,h6 { margin:0; padding:0; line-height: unset; font-weight: unset; font-size: unset;}

/* rotate phone message */
.rotate {
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,.9);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  margin:0;
  padding:0;
  z-index: 100000;
  visibility: hidden;
  opacity: 0;
}

.rotate-image{
  width:30vw;
}


@media screen and (orientation:landscape) and (max-width:1024px) {
  .rotate {
    visibility: visible;
    opacity: 1;
  }
}
