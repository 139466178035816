.gallery{
    font-family: 'Volvo Novum';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    background: white;
    overflow-x: hidden;
    overflow-y:hidden;
}



.gallery-text {
    position: absolute;
    top:20%;
    left:5.3vw;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 3.733vw;
    color:white;
    letter-spacing: 0.12em;
}


.learn-button{
    background: transparent;
    border: 1px solid black;
    border-radius: 1vw;
    width: 147px;
    color:black;
    font-family: Volvo Novum;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top:10vw;
    transition: .5s ease-in-out all;
}

.learn-button:hover{
    background: black;
    color:white;
}

.gallery-slides{
    position: relative;
    top:10%;
    overflow-x: scroll;
    overflow-y:hidden;
    width:100vw;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.gallery-slides-container{
    width:279vw;
    
}

.gallery-slide {
    scroll-snap-align: start;
    position: inline-block;
    width: 85vw;
    padding-left:6vw;
    
}

.gallery-nav{
    position: relative;
    display: flex;
    width:50vw;
    margin-top:11.5vh;
    left:25vw;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}

.gallery-arrow {
    cursor: pointer;
    width:7vw;
}




.gallery .title-block {
    position: absolute;
    top:0;
    margin:0;
    margin-top:57vh;
    width:77vw;
    opacity:1;
    visibility: hidden;
    opacity: 0;
}

.gallery .title{
    color:black;
    font-size:9.6vw;
}
.gallery .sub-title{
    color: #6D6D6D;
    font-size:4.2vw;
    line-height:6.4vw;
    font-weight: 300;
}

.gallery .title{
    color:black;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.gallery-slides::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .gallery-slides{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

  }



/* specific to over 420 */
@media only screen and (min-width: 420px) {
    .gallery{
        font-size: 36px;
        line-height: 42px;
    }

    .gallery .title{
        font-size:36px;
    }
    .gallery .sub-title{
        font-size:16px;
        line-height:24px;
        width:300px;
    }

    .gallery-slides-container{
        width:1275px;
    }
    
    .gallery-slide {
        width: 300px;
        padding-left:25px;
    }

    .learn-button{
        border-radius: 5px;
        width: 200px;
        height:50px;
        font-size: 14px;
        line-height:14px;
        margin-top:45px;
    }

    .gallery-nav{
        width:300px;
        margin-top:100px;
        left:50%;
        transform: translate(-50%,0);
    }
    
    .gallery-arrow {
        width:40px;
    }
    
   
}


/* specific to over 768 */
@media only screen and (min-width: 768px) {
    
}